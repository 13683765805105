import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Modal from 'react-modal'

Modal.setAppElement('#root')

function Menu ({ 
  onAboutClick, 
  onTestModeClick, 
  onImportClick, 
  importText, 
  setImportText,
  onImportSubmit
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  function toggleModal () {
    setIsOpen(!isOpen)
  }

  function handleImportClick() {
    setIsOpen(false);
    setIsImporting(true);
    onImportClick();
  }

  function handleAboutClick() {
    setIsOpen(false);
    onAboutClick();
  }

  function handleTestModeClick() {
    setIsOpen(false);
    onTestModeClick();
  }

  function handleClearClick () {
    if (!window.confirm('Are you sure you want to clear all data?')) return
    
    window.localStorage.clear()
    window.location.reload()
  }

  function handleExportClick() {
    setIsOpen(false)
    setIsExporting(true)
  }

  return (
    <div>
      <FaBars onClick={toggleModal} className='Menu-button ma3' />
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel='Options Menu'
        className='Modal w-100 tr'
        overlayClassName='Overlay'
      >
        <FaTimes onClick={toggleModal} />
        <ul className='tr'>
          <li className='pa3 ma0 bb b--black-20' onClick={handleAboutClick}>About</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleTestModeClick}>
            {`${window.location.hash === '#test' ? 'Disable' : 'Enable'}`} Test Mode
          </li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleExportClick}>Export Data</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleImportClick}>Import Data</li>
          <li className='pa3 ma0 bb b--black-20' onClick={handleClearClick}>Clear All Data</li>
        </ul>
      </Modal>
      
      {isExporting && (
        <div className='mt2'>
          <textarea
            className='w-100 h5 mb2'
            value={localStorage.getItem('ends')}
            readOnly
            onClick={e => e.target.select()}
          />
          <br />
          <button
            className='w-100 bg-white black ba b--black'
            onClick={() => setIsExporting(false)}
          >
            Close Export
          </button>
        </div>
      )}

      {isImporting && (
        <div className='mt2'>
          <textarea
            className='w-100 h5 mb2'
            value={importText}
            onChange={e => setImportText(e.target.value)}
          />
          <br />
          <div className='flex justify-between'>
            <button
              className='w-50 mr2 bg-white black ba b--black'
              onClick={() => {
                setIsImporting(false)
                setImportText('')
              }}
            >
              Cancel
            </button>
            <button
              className='w-50 bg-white black ba b--black'
              onClick={onImportSubmit}
            >
              Import Data
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Menu
