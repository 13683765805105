import React, { useState, useEffect } from 'react'
import './App.css'
import './tachyons.css'
import ScoringButtons from './ScoringButtons'
import ScoreDisplay from './ScoreDisplay'
import ScoreTable from './ScoreTable'
import About from './About'
import Menu from './Menu'
import ScoreScatterPlot from './ScoreScatterPlot'

import getScoreColor from './GetScoreColor'

import exportedData from './data'
import upgradeEnds from './upgradeEnds'

const VERSION = 1

function App () {
  const [isSimulated, setIsSimulated] = useState(false)
  const [importText, setImportText] = useState('')
  const [isTestMode] = useState(window.location.hash === '#test')
  const [isExportMode] = useState(window.location.hash === '#export')
  const [showAbout, setShowAbout] = useState(false)
  const [isImporting, setIsImporting] = useState(false) // Add this line

  const [filterSimulated, setFilterSimulated] = useState(
    localStorage.getItem('filterSimulated') || 'Any'
  )
  const [filterDate, setFilterDate] = useState(
    localStorage.getItem('filterDate') || 'All Time'
  )
  const [filterDistance, setFilterDistance] = useState(
    localStorage.getItem('filterDistance') || 'All Distances'
  )

  useEffect(() => {
    localStorage.setItem('filterSimulated', filterSimulated)
  }, [filterSimulated])

  useEffect(() => {
    localStorage.setItem('filterDate', filterDate)
  }, [filterDate])

  useEffect(() => {
    localStorage.setItem('filterDistance', filterDistance)
  }, [filterDistance])

  const getEndsFromLocalStorage = () => {
    const storedEnds = localStorage.getItem('ends')
    if (storedEnds) return parseEndsStringData(storedEnds)
    return []
  }

  const initialEnds = isTestMode
    ? parseEndsStringData(exportedData)
    : getEndsFromLocalStorage()

  const [ends, setEnds] = useState(initialEnds)
  const [scoringEnd, setScoringEnd] = useState(false)
  const [currentEndScores, setCurrentEndScores] = useState([])
  const [collapsed, setCollapsed] = useState(false)
  const [distance, setDistance] = useState(
    parseInt(localStorage.getItem('distance')) || 18
  )
  const [editingEndIndex, setEditingEndIndex] = useState(null)

  // Update localStorage whenever the ends state changes
  useEffect(() => {
    if (!isTestMode) {
      localStorage.setItem('ends', JSON.stringify(ends))
    }
  }, [ends, isTestMode])

  useEffect(() => {
    localStorage.setItem('distance', distance)
  }, [distance])

  function handleImportClick () {
    setIsImporting(true)
  }

  function importEndsFromText () {
    setIsImporting(false)
    try {
      const importedEnds = parseEndsStringData(importText)
      const newEnds = [...ends, ...importedEnds].reduce((acc, end) => {
        const index = acc.findIndex(e => e.id === end.id)
        if (index !== -1) {
          acc[index] = end
        } else {
          acc.push(end)
        }
        return acc
      }, [])
      setEnds(newEnds)
      window.alert('Ends data imported from text')
    } catch (err) {
      window.alert('Could not import data: ', err)
    }
  }

  function setIsTestMode (bool) {
    if (bool) return (window.location.hash = '#test')
    window.location.hash = ''
  }

  function addEnd (scores, distance) {
    const timestamp = new Date()
    const id = generateId()
    setEnds([
      ...ends,
      { id, timestamp, scores, distance, isSimulated, version: VERSION }
    ])
  }

  function startEnd () {
    setScoringEnd(true)
  }

  function discardEnd () {
    if (editingEndIndex !== null) {
      const updatedEnds = ends.filter((_, index) => index !== editingEndIndex)
      setEnds(updatedEnds)
      setEditingEndIndex(null)
    }
    setScoringEnd(false)
    setCurrentEndScores([])
  }

  function finishEnd () {
    if (editingEndIndex !== null) {
      const updatedEnds = [...ends]
      updatedEnds[editingEndIndex] = {
        ...updatedEnds[editingEndIndex],
        scores: currentEndScores,
        distance: distance,
        isSimulated: isSimulated
      }
      setEnds(updatedEnds)
      setEditingEndIndex(null)
    } else {
      addEnd(currentEndScores, distance)
    }
    setScoringEnd(false)
    setCurrentEndScores([])
  }

  function addArrowScore (score) {
    setCurrentEndScores([...currentEndScores, score])
  }

  function removeLastScore () {
    setCurrentEndScores(currentEndScores.slice(0, -1))
  }

  function selectEndForEditing (index) {
    setEditingEndIndex(index)
    setCurrentEndScores(ends[index].scores)
    setDistance(ends[index].distance)
    setIsSimulated(ends[index].isSimulated)
    setScoringEnd(true)
  }

  function handleAboutClick () {
    setShowAbout(!showAbout)
  }

  function handleTestModeClick () {
    setIsTestMode(!isTestMode)
  }

  const uniqueDates = new Set(ends.map(end => end.timestamp.toDateString()))
    .size

  const getUniqueDistances = () => {
    const distances = ends.map(end => end.distance)
    return ['All Distances', ...new Set(distances)]
  }

  if (isExportMode) {
    return (
      <div className='App content pa3 mt3 mb6'>
        <textarea className='w-100' style={{ height: '90vh' }}>
          {JSON.stringify(ends)}
        </textarea>
      </div>
    )
  }

  return (
    <div className='App content pa3 mt3 mb6'>
      <Menu
        onAboutClick={handleAboutClick}
        onTestModeClick={handleTestModeClick}
        onImportClick={handleImportClick}
        importText={importText}
        setImportText={setImportText}
        onImportSubmit={importEndsFromText}
      />
      {scoringEnd ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            justifyContent: 'space-between'
          }}
        >
          <ScoreDisplay
            currentEndScores={currentEndScores}
            getScoreColor={getScoreColor}
          />
          <div>
            <label>
              Distance (meters)
              <input
                type='number'
                inputMode='numeric'
                value={distance}
                onChange={e => setDistance(parseInt(e.target.value))}
                className='pa3 mb3'
                min='1'
              />
            </label>
            <label>
              Simulated Distance
              <input
                type='checkbox'
                checked={isSimulated}
                onChange={e => setIsSimulated(e.target.checked)}
              />
            </label>
            <ScoringButtons
              addArrowScore={addArrowScore}
              getScoreColor={getScoreColor}
              style={{ flexGrow: 1 }}
            />
            <div className='flex justify-around items-center'>
              {currentEndScores.length > 0 ? (
                <button
                  className='w-50 pa3 bl bt bb b--black bg-white black'
                  onClick={removeLastScore}
                >
                  Remove Last Score
                </button>
              ) : (
                <button
                  className={`${
                    currentEndScores.length ? 'w-50 bl bt bb' : 'w-100 ba'
                  } pa3 b--black bg-white black`}
                  onClick={discardEnd}
                >
                  Discard End
                </button>
              )}
              <button
                className={`w-50 pa3 ba b--black bg-green ${
                  !currentEndScores.length && 'dn'
                }`}
                onClick={finishEnd}
                disabled={!currentEndScores.length}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {(showAbout || !ends.length) && <About />}
          <div className='score-end-button w-100 pa3 mb0 bg-white'>
            <button
              className='pa3 mb2 ba br b--black bg-black-80 white'
              onClick={startEnd}
            >
              Score End
            </button>
          </div>
          <div className='mt3 tr flex justify-between items-center'>
            <div className='w-50'>
              {uniqueDates > 1 && (
                <button
                  className='tl bg-white black'
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {collapsed ? 'Expand Dates' : 'Collapse Dates'}
                </button>
              )}
            </div>
            <div className='w-50'>
              {ends.length > 0 && (
                <div>
                  <select
                    className='ml2'
                    value={filterDate}
                    onChange={e => setFilterDate(e.target.value)}
                  >
                    {[
                      'All Time',
                      '1 Year',
                      '6 months',
                      '3 months',
                      '1 month',
                      '2 weeks',
                      '1 week',
                      '3 days',
                      '1 day',
                      '12 hours',
                      '6 hours',
                      '1 hour'
                    ].map(date => (
                      <option key={date} value={date}>
                        {date}
                      </option>
                    ))}
                  </select>
                  <label>
                    <select
                      className='ml2'
                      value={filterDistance}
                      onChange={e => setFilterDistance(e.target.value)}
                    >
                      {getUniqueDistances().map(distance => (
                        <option key={distance} value={distance}>
                          {distance === 'All Distances'
                            ? distance
                            : `${distance} meters`}
                        </option>
                      ))}
                    </select>
                  </label>
                  <select
                    className='ml2'
                    value={filterSimulated}
                    onChange={e => setFilterSimulated(e.target.value)}
                  >
                    {['Any', 'Not Simulated', 'Simulated'].map(simulated => (
                      <option key={simulated} value={simulated}>
                        {simulated}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          {!scoringEnd && (
            <>
              <ScoreScatterPlot
                ends={ends}
                filterDistance={filterDistance}
                filterDate={filterDate}
                filterSimulated={filterSimulated}
              />
              <ScoreTable
                ends={ends}
                collapsed={collapsed}
                onEndSelect={selectEndForEditing}
                filterDistance={filterDistance}
                filterSimulated={filterSimulated}
                filterDate={filterDate}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

function parseEndsStringData (endsData) {
  const parsedEnds = JSON.parse(endsData)
  const endsWithDate = parsedEnds.map(end => {
    if (!end.id) end.id = generateId(end.timestamp)
    return {
      ...end,
      timestamp: new Date(end.timestamp)
    }
  })
  return upgradeEnds(endsWithDate)
}

function generateId (time) {
  time = new Date(time || Date.now())
  return time.valueOf().toString(36)
}

export default App
